.morris-hover {
  position: absolute;
  z-index: 1000;
}

.morris-hover.morris-default-style {
  border-radius: 10px;
  padding: 6px;
  color: #666;
  background: rgba(255,255,255,0.8);
  border: solid 2px rgba(230,230,230,0.8);
  font-family: sans-serif;
  font-size: 12px;
  text-align: center;
}

.morris-hover.morris-default-style .morris-hover-row-label {
  font-weight: bold;
  margin: 0.25em 0;
}

.morris-hover.morris-default-style .morris-hover-point {
  white-space: nowrap;
  margin: 0.1em 0;
}

;
.steps-indicator {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 30px;
  padding: 0;
  margin: 0;
  list-style: none;
}

.steps-indicator:before {
  position: absolute;
  height: 1px;
  background-color: #e6e6e6;
  content: '';
}

.steps-indicator.steps-2:before {
  right: calc(25%);
  left: calc(25%);
}

.steps-indicator.steps-3:before {
  right: calc(16.666666666666668%);
  left: calc(16.666666666666668%);
}

.steps-indicator.steps-4:before {
  right: calc(12.5%);
  left: calc(12.5%);
}

.steps-indicator.steps-5:before {
  right: calc(10%);
  left: calc(10%);
}

.steps-indicator.steps-6:before {
  right: calc(8.333333333333334%);
  left: calc(8.333333333333334%);
}

.steps-indicator.steps-7:before {
  right: calc(7.142857142857143%);
  left: calc(7.142857142857143%);
}

.steps-indicator.steps-8:before {
  right: calc(6.25%);
  left: calc(6.25%);
}

.steps-indicator.steps-9:before {
  right: calc(5.555555555555555%);
  left: calc(5.555555555555555%);
}

.steps-indicator.steps-10:before {
  right: calc(5%);
  left: calc(5%);
}

.steps-indicator * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.steps-indicator li {
  position: relative;
  float: left;
  padding: 0;
  padding-top: 10px;
  margin: 0;
  line-height: 15px;
  text-align: center;
}

.steps-indicator li a {
  font-weight: bold;
  color: #808080;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.25s;
}

.steps-indicator li a:before {
  position: absolute;
  top: -7px;
  left: calc(43%);
  width: 14px;
  height: 14px;
  background-color: #e6e6e6;
  border-radius: 100%;
  content: '';
  transition: 0.25s;
}

.steps-indicator li a:hover {
  color: #4d4d4d;
}

.steps-indicator.steps-2 li {
  width: calc(50%);
}

.steps-indicator.steps-3 li {
  width: calc(33.333333333333336%);
}

.steps-indicator.steps-4 li {
  width: calc(25%);
}

.steps-indicator.steps-5 li {
  width: calc(20%);
}

.steps-indicator.steps-6 li {
  width: calc(16.666666666666668%);
}

.steps-indicator.steps-7 li {
  width: calc(14.285714285714286%);
}

.steps-indicator.steps-8 li {
  width: calc(12.5%);
}

.steps-indicator.steps-9 li {
  width: calc(11.11111111111111%);
}

.steps-indicator.steps-10 li {
  width: calc(10%);
}

.steps-indicator.steps-11 li {
  width: calc(9.090909090909092%);
}

.steps-indicator li.default {
  pointer-events: none;
}

.steps-indicator li.default a:hover {
  color: #808080;
}

.steps-indicator li.current,
.steps-indicator li.editing {
  pointer-events: none;
}

.steps-indicator li.current a:before {
  background-color: #808080;
}

.steps-indicator li.done a:before {
  background-color: #339933;
}

.steps-indicator li.editing a:before {
  background-color: #ff0000;
}