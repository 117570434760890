.browserSupport-body .pageText-box {
  margin: 100px auto 0;
  text-align: center;
}

.browserSupport-body .pageTitle {
  font-size: 50px;
  line-height: 60px;
  font-weight: 700;
}

.browserSupport-body p {
  font-size: 20px;
  line-height: 22px;
  font-weight: 300;
}

.browserSupport-body p strong {
  font-weight: 700;
}

.downloadBrowser-link {
  margin: 0 10px;
  display: inline-block;
  position: relative;
  top: 0;
  transition: all .4s ease;
  -moz-transition: all .4s ease;
  -webkit-transition: all .4s ease;
  -o-transition: all .4s ease;
  -ms-transition: all .4s ease;
}

.downloadBrowser-link span {
  text-align: center;
  font-size: 10px;
  position: absolute;
  left: 0;
  color: white;
  width: 100%;
  bottom: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  visibility: hidden;
  transition: all .4s ease;
  -moz-transition: all .4s ease;
  -webkit-transition: all .4s ease;
  -o-transition: all .4s ease;
  -ms-transition: all .4s ease;
}

.downloadBrowser-link:hover {
  top: -5px;
}

.downloadBrowser-link:hover span {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
  bottom: -20px;
}

.downloadBrowser-link img {
  width: 50px;
}

.browserIcons-box {
  margin-top: 40px;
}