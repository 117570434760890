@media only screen and (max-width: 768px) {
  /* ! Header */

  .top-header .menu-button {
    top: 10px;
    left: 10px;
    right: auto;
    z-index: 20;
  }

  .top-header .logo {
    padding-left: 0;
    text-align: center;
  }

  .top-header .top-nav {
    position: absolute;
    padding-top: 0;
    top: 15px;
    right: 0;
  }

  .top-header .top-nav .nav-right {
    padding: 0;
  }

  /*! Nav Categories */

  #nav-container {
    -webkit-transition: -webkit-transform .6s;
    -khtml-transition: -webkit-transform .6s;
    -moz-transition: -webkit-transform .6s;
    -ms-transition: -webkit-transform .6s;
    transition: transform .6s;
  }

  #nav-container.open {
    -webkit-transform: translateX(220px);
    -khtml-transform: translateX(220px);
    -moz-transform: translateX(220px);
    -ms-transform: translateX(220px);
    transform: translateX(220px);
  }

  #content {
    -webkit-transition: -webkit-transform .6s;
    -moz-transition: -moz-transform .6s;
    -ms-transition: -ms-transform .6s;
    transition: transform .6s;
  }

  #content.close {
    -webkit-transform: translateX(220px);
    -khtml-transform: translateX(220px);
    -moz-transform: translateX(220px);
    -ms-transform: translateX(220px);
    transform: translateX(220px);
  }

  /*! List */

  .item-manage-buttons > a {
    font-size: 1.5em;
  }

  /*! Detail */

  .breadcrumb {
    left: 0;
  }

  .as-sortable-placeholder,
  .btn-image-upload,
  .image-thumb,
  .btn-image-upload button {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }

  .gallery-wrapper {
    text-align: center;
  }
}