/*! Glyphicons */

.carousel-control .fa-chevron-left,
.carousel-control .fa-chevron-right {
  position: absolute;
  top: 50%;
  z-index: 5;
  display: inline-block;
}

.carousel-control .fa-chevron-left {
  left: 50%;
}

.carousel-control .fa-chevron-right {
  right: 50%;
}

@media screen and (min-width: 768px) {
  .carousel-control .fa-chevron-left,
  .carousel-control .fa-chevron-right {
    width: 30px;
    height: 30px;
    margin-top: -15px;
    margin-left: -15px;
    font-size: 30px;
  }
}

.table-dynamic .table-bordered thead th .fa-chevron-up,
.table-dynamic .table-bordered thead th .fa-chevron-down {
  position: absolute;
  color: #999;
}

.table-dynamic .table-bordered thead th .fa-chevron-up:hover,
.table-dynamic .table-bordered thead th .fa-chevron-down:hover {
  color: #674e9e;
  cursor: pointer;
}

.table-dynamic .table-bordered thead th .fa-chevron-up.active,
.table-dynamic .table-bordered thead th .fa-chevron-down.active {
  color: #674e9e;
}

.table-dynamic .table-bordered thead th .fa-chevron-up {
  top: 4px;
  right: 5px;
}

.table-dynamic .table-bordered thead th .fa-chevron-down {
  top: 18px;
  right: 5px;
}

.page-tasks .task-list .view:hover .fa-pencil,
.page-tasks .task-list .view:hover .fa-remove {
  display: block;
}

.page-tasks .task-list .view .fa-pencil,
.page-tasks .task-list .view .fa-remove {
  display: none;
  position: absolute;
  top: 14px;
  color: #999999;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.page-tasks .task-list .view .fa-pencil:hover,
.page-tasks .task-list .view .fa-remove:hover {
  cursor: pointer;
  color: #333333;
  -webkit-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
  transform: scale(1.3);
}

.page-tasks .task-list .view .fa-pencil {
  right: 40px;
}

.page-tasks .task-list .view .fa-remove {
  right: 16px;
}

.top-header .top-nav .nav-left > li ul.dropdown-menu .fa,
.top-header .top-nav .nav-right > li ul.dropdown-menu .fa {
  margin-right: 10px;
}

/* Force the switch */

.glyphicon {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.glyphicon-chevron-up:before {
  content: "\f077";
}

.glyphicon-chevron-down:before {
  content: "\f078";
}

.glyphicon-chevron-left:before {
  content: "\f053";
}

.glyphicon-chevron-right:before {
  content: "\f054";
}