.wrapper {
  position: relative;
}

.divider {
  height: 20px;
  clear: both;
}

.float-right {
  float: right;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-small {
  font-size: .8em;
}

.single-row {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.text-bold {
  font-weight: bold !important;
}

.no-border {
  border: none;
}

.top-header,
.pagination > li > a,
.pagination > li > span {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/*! Header */

.top-header {
  height: 60px;
  line-height: 60px;
}

.breadcrumb,
.alert,
#nav-container,
#content {
  top: 60px;
}

.top-header .logo {
  text-align: left;
  padding: 0;
  padding-left: 25px;
  height: inherit;
}

.full-page .top-header .logo {
  padding-left: 0;
}

.top-header .logo img {
  height: 22px;
}

.top-header .logo.text-center {
  text-align: center;
  width: 100%;
}

.top-header .menu-button {
  top: 18px;
  font-size: 24px;
  text-align: center;
}

.nav-right .dropdown-toggle .hidden-xs {
  padding: 0 12px 0 3px !important;
  text-transform: uppercase;
  font-size: 0.8em;
  color: #666;
}

.top-header .top-nav {
  padding: 15px 20px 0;
}

.dropdown-menu a {
  cursor: pointer;
}

.top-header .dropdown-menu.panel {
  left: auto;
  right: -115px;
  width: 230px;
}

.dropdown-menu .list-group .media-body {
  color: #000;
}

.dropdown-menu .list-group .pull-left {
  margin-right: 8px;
}

.text-lowercase {
  text-transform: lowercase;
}

/*! Colors */

a.text-white:hover,
a.text-white:active,
.text-white {
  color: #FFF;
}

.badge-muted,
.label-muted {
  background: #CCC;
}

.bg-muted {
  background: #E5E5E5;
}

.badge-grey,
.label-grey,
.bg-grey {
  color: #FFF;
  background-color: #999;
}

a.text-success:hover,
a.text-success:active,
.text-success {
  color: #a3c86d !important;
}

a.text-info:hover,
a.text-info:active,
.text-info {
  color: #61ACCC !important;
}

a.text-warning:hover,
a.text-warning:active,
.text-warning {
  color: #fdd761 !important;
}

.badge-purple,
.label-purple,
.bg-purple {
  color: #FFF;
  background-color: #674E9E;
}

a.text-purple:hover,
a.text-purple:active,
.text-purple {
  color: #674E9E !important;
}

.badge-orange,
.label-orange,
.bg-orange {
  color: #FFF;
  background-color: #FFAB57;
}

a.text-orange:hover,
a.text-orange:active,
.text-orange {
  color: #FFAB57 !important;
}

.badge-blue,
.label-blue,
.bg-blue {
  color: #FFF;
  background-color: #5762ff;
}

a.text-blue:hover,
a.text-blue:active,
.text-blue {
  color: #5762ff !important;
}

.item-manage-buttons a.disabled:hover,
.item-manage-buttons a.disabled:active,
.item-manage-buttons a.disabled {
  color: #CCC !important;
}

.item-manage-buttons a.text-success.fa-check-circle:hover,
.item-manage-buttons a.text-success {
  color: #5cb85c;
}

.item-manage-buttons a.text-warning.fa-check-circle:hover,
.item-manage-buttons a.text-warning {
  color: #f0ad4e;
}

a.fa:hover,
a.fa:active {
  text-decoration: none;
}

/*! Page */

.breadcrumb {
  position: fixed;
  z-index: 15 !important;
  left: 220px;
  right: 0;
  padding: 8px 0;
  background-color: rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid #dfdfdf;
  border-radius: 0;
}

.page.page-form-ele {
  padding-top: 50px;
}

.panel-body {
  position: relative;
}

.sort-arrows {
  line-height: 3px;
  width: 20px;
  display: inline-block;
  vertical-align: bottom;
  text-align: center;
}

.sort-arrows .fa-sort-asc,
.sort-arrows .fa-sort-desc {
  font-size: 14px;
  display: inline-block;
  height: 9px;
  overflow: hidden;
  width: 100%;
  vertical-align: -webkit-baseline-middle;
}

.sort-arrows .fa-sort-desc {
  line-height: 0;
}

/*! Buttons */

.badge-primary,
.label-primary,
.btn-primary,
.btn-primary[disabled] {
  background-color: #A3C86D;
  border-color: #97C15A;
}

.btn-primary:active,
.btn-primary:hover {
  background-color: #90BD4F;
  border-color: #79A13D;
}

.btn-active,
.btn-active:focus,
.btn-active:active,
.btn-active:hover {
  font-weight: bold;
  color: #97C15A;
  background-color: #fff;
  border-color: #e2e2e4;
}

.badge-muted,
.label-muted,
.btn-muted {
  background-color: #E5E5E5;
  border-color: #CCC;
}

.btn-muted:active,
.btn-muted:hover {
  background-color: #CCC;
  border-color: #AAA;
}

.file-button .btn .fa {
  margin-right: 5px;
  color: #EEE;
}

/*! Nav */

#nav-container::-webkit-scrollbar {
  display: none;
}

#nav-container {
  z-index: 100 !important;
  overflow-y: scroll;
}

#nav {
  z-index: 100 !important;
}

#nav-container .slim-scroll-div {
  position: relative;
  overflow: hidden;
  overflow-y: scroll;
  width: auto;
  height: 100%;
}

#nav a {
  cursor: pointer;
}

#nav ul {
  display: block;
}

#nav>li>a {
  padding: 15px 0 15px 25px;
}

.fullwidth #nav>li>ul,
.fullwidth #nav>li>i,
.fullwidth #nav>li>a>span {
  display: none;
}

.fullwidth #nav-container {
  width: 70px;
}

.fullwidth .breadcrumb,
.fullwidth .alert,
.fullwidth #content {
  left: 70px;
}

/*! Dashboard */

a.panel.mini-box {
  display: block;
  cursor: pointer;
}

a.panel-item:active,
a.panel-item:hover,
a.panel.mini-box:active,
a.panel.mini-box:hover {
  text-decoration: none;
}

a.panel-item.bg-grey:active,
a.panel-item.bg-grey:hover,
a.panel.mini-box.bg-grey:active,
a.panel.mini-box.bg-grey:hover {
  color: #FFF;
  background-color: #999;
}

a.panel-item.bg-info:active,
a.panel-item.bg-info:hover,
a.panel.mini-box.bg-info:active,
a.panel.mini-box.bg-info:hover {
  color: #FFF;
  background-color: #7ACBEE;
}

a.panel-item.bg-success:active,
a.panel-item.bg-success:hover,
a.panel.mini-box.bg-success:active,
a.panel.mini-box.bg-success:hover {
  color: #FFF;
  background-color: #A3C86D;
}

a.panel-item.bg-warning:active,
a.panel-item.bg-warning:hover,
a.panel.mini-box.bg-warning:active,
a.panel.mini-box.bg-warning:hover {
  color: #FFF;
  background-color: #FDD761;
}

a.panel-item.bg-danger:active,
a.panel-item.bg-danger:hover,
a.panel.mini-box.bg-danger:active,
a.panel.mini-box.bg-danger:hover {
  color: #FFF;
  background-color: #FF7857;
}

#dashboard-tags-list {
  line-height: 30px;
}

.panel-profile .profile {
  margin: 5px 15px 5px 5px;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  line-height: 102px;
  text-align: center;
}

.website-check .progress {
  height: 8px;
  margin-bottom: 0;
}

.website-main-progress {
  border-radius: 0;
  background-color: #e5e5e5;
}

.progress.header-progress {
  border-radius: 0;
  width: 100%;
  height: 3px;
}

.breadcrumb .progress.header-progress {
  position: absolute;
  top: 100%;
  margin: 0;
}

.website-check .list-group-item {
  border: none;
  border-top: 2px solid #eff2f7;
}

.list-group-item .box-icon {
  line-height: 27px !important;
}

.php-icon {
  font-weight: bold;
  color: #333;
  font-size: 11px;
  line-height: 17px;
  vertical-align: text-top;
}

.php-icon:before {
  content: "php";
}

@media (max-width: 400px) {
  #dashboard-logo {
    margin: 8px 20px 5px 10px;
  }

  .panel-heading.bg-primary > p {
    display: none;
  }
}

.panel-body .list-unstyled {
  margin-bottom: 0;
}

.list-info li .icon {
  color: #A3C86D;
}

/*! List */

.loading-label {
  padding: 40px 0;
  -webkit-animation: pulse 1s alternate infinite;
  -moz-animation: pulse 1s alternate infinite;
  -o-animation: pulse 1s alternate infinite;
  animation: pulse 1s alternate infinite;
}

.loading-label:after {
  content: "...";
}

@-webkit-keyframes pulse {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes pulse {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes pulse {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.categories-empty,
.table-empty {
  width: 100%;
  text-align: center;
  padding: 20px 0;
}

.table-empty {
  background-color: #fafafa;
  border-bottom: 1px solid #ddd;
}

.angular-ui-tree-empty {
  display: none;
}

.table .fa[class*="-circle"] {
  font-size: 1.2em;
}

.item-manage-buttons span,
.item-manage-buttons a {
  font-size: 1.2em;
  color: #888;
  cursor: pointer;
}

.item-manage-buttons a:hover,
.item-manage-buttons a:active {
  text-decoration: none;
  color: #666;
}

.item-manage-buttons a.fa-info-circle.ng-disabled,
.item-manage-buttons a.fa-info-circle.ng-disabled:hover,
.item-manage-buttons a.fa-info-circle.ng-disabled:active {
  color: #CCC;
}

.panel.panel-category .item-manage-buttons a.fa-info-circle:hover,
.panel.panel-category .item-manage-buttons a.fa-info-circle:active,
.item-manage-buttons a.fa-info-circle:hover,
.item-manage-buttons a.fa-info-circle:active {
  color: #63C2EB;
}

.panel.panel-category .item-manage-buttons a.fa-minus-circle:hover,
.panel.panel-category .item-manage-buttons a.fa-minus-circle:active,
.item-manage-buttons a.fa-minus-circle:hover,
.item-manage-buttons a.fa-minus-circle:active {
  color: #FF643E;
}

.panel.panel-category .item-manage-buttons a.fa-check-circle:hover,
.panel.panel-category .item-manage-buttons a.fa-check-circle:active,
.item-manage-buttons a.fa-check-circle:hover,
.item-manage-buttons a.fa-check-circle:active {
  color: #97C15A;
}

.panel .panel-heading {
  text-transform: none;
}

.page-table .panel .panel-heading {
  padding: 10px 15px;
}

.page-table .panel .panel-heading strong {
  display: inline-block;
  line-height: 32px;
}

.panel-default .panel-heading a.fa {
  color: inherit;
  font-size: initial;
}

.panel .panel-heading .form-group {
  margin-bottom: 0;
}

.panel .panel-heading .form-control {
  display: inline-block;
  width: 90%;
  height: inherit;
}

.panel-heading > .row > strong .fa,
.panel-heading > strong .fa {
  padding-right: 5px;
}

.table-dynamic .table-footer {
  margin: 3px 0;
}

.item-manage-buttons a.fa-toggle-on {
  color: #8fb25a;
}

.item-manage-buttons a.fa-toggle-off {
  opacity: 0.5;
}

.item-manage-buttons a.fa-star.text-warning {
  color: #fdd761;
}

td > .form-control {
  border: none;
  background-color: transparent;
  height: auto;
  padding: 0;
}

tr.disabled {
  opacity: 0.5;
}

.table.in-section thead>tr:first-child>th {
  background-color: #fafafa;
  border-bottom: 2px solid #ddd !important;
}

.table tr ul {
  padding-left: 20px;
}

.table-bordered>tfoot>tr>td {
  border: none;
}

.table-bordered>tfoot>tr:first-child>td {
  border-top: 2px solid #ddd !important;
}

.table-dynamic .table-filters {
  padding: 8px 15px;
  margin: 0;
  background-color: #fafafa;
}

.table-dynamic .table-filters.row {
  padding: 8px 0;
}

.table-dynamic .table-filters button {
  margin: 0 1px;
}

.table-dynamic .table-filters .btn-select,
.table-dynamic .table-filters .form-control {
  display: inline-block;
  width: 80%;
  height: 28px;
  border: 1px solid #e2e2e4;
  background-color: white;
}

/*! Modal */

.modal-backdrop.am-fade {
  opacity: .5;
  transition: opacity .15s linear;
}

.modal-backdrop.am-fade.ng-enter {
  opacity: 0;
}

.modal-backdrop.am-fade.ng-enter.ng-enter-active {
  opacity: .5;
}

.modal-backdrop.am-fade.ng-leave {
  opacity: .5;
}

.modal-backdrop.am-fade.ng-leave.ng-leave-active {
  opacity: 0;
}

.panel .panel-heading .tooltip {
  text-transform: none;
}

.modal-container {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 1040;
  background-color: rgba(0,0,0,.5);
  -webkit-animation-duration: .15s;
  animation-duration: .15s;
}

.modal-container .modal {
  z-index: 1050;
  display: block;
  top: 50px;
}

/*! Alert */

.alert.bottom-right {
  z-index: 10;
  position: absolute;
  bottom: 0;
  right: 10px;
}

.alert.bottom-left {
  z-index: 10;
  position: absolute;
  bottom: 0;
  left: 10px;
}

.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.alert-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.alert-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}

.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

button.close {
  padding-left: 3px;
  line-height: 0.8;
}

/*! Main Alert */

.alert.am-fade {
  position: fixed;
  z-index: 20 !important;
  top: 60px;
  left: 220px;
  right: 0;
  padding: 10px 15px;
  border: none;
  border-bottom: 1px solid;
  border-radius: 0;
}

.alert.am-fade.alert-success {
  color: white;
  background-color: #5cb85c;
  border-color: #4E9D4E;
}

.alert.am-fade.alert-info {
  color: white;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.alert.am-fade.alert-warning {
  color: white;
  background-color: #f0ad4e;
  border-color: #CC9342;
}

.alert.am-fade.alert-danger {
  color: white;
  background-color: #d9534f;
  border-color: #B94743;
}

/*! Input */

.input-group-addon {
  background-color: #FAFAFA;
}

.form-control[disabled] {
  color: #2A2A2A;
  background-color: #EFEFEF;
}

.form-horizontal .form-group {
  margin-left: 0;
  margin-right: 0;
}

label .text-small,
label small {
  font-size: .8em;
  font-weight: normal;
}

.input-caption {
  display: block;
  font-size: .8em;
  margin-top: -8px;
  color: #999;
}

/*! Checkbox */

label.ui-checkbox {
  font-weight: normal;
}

.ui-checkbox input[type="checkbox"]:checked + span:after {
  font-family: 'FontAwesome';
  content: "\f00c";
  color: #FFF;
  top: 3px;
  left: -17px;
  background-image: none;
}

.ui-checkbox input[type="checkbox"]:checked + span:before {
  background-color: #A3C86D;
  border-color: #A3C86D;
}

.inline-checkbox .checkbox-wrapper {
  display: inline-block;
}

/*! Datepicker */

.picker__input {
  cursor: text !important;
}

.picker__footer {
  display: none;
}

.picker__list {
  padding-top: 0;
  padding-bottom: 0;
}

.picker__list .picker__button--clear {
  display: none;
}

/*! WYSIWYG AngularText */

.ta-toolbar {
  margin-bottom: 5px;
}

.btn-toolbar {
  color: #666;
  border-color: #E2E2E8;
  background-color: #FAFAFA;
}

.btn-toolbar:hover {
  color: #333;
}

.btn-toolbar.active {
  color: #FFF;
  background-color: #A3C86D;
  border-color: #97C15A;
}

textarea {
  max-width: 100%;
}

.ta-scroll-window>.ta-bind,
.ta-editor.ta-html,
.ta-scroll-window.form-control {
  min-height: 200px;
}

/* Tags Input */

.tags-input .host {
  margin: 0;
}

.tags-input .tags {
  width: 100%;
  min-height: 32px;
  padding: 6px 12px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #e2e2e4;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  -moz-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  -ms-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  cursor: default;
}

.tags-input .tags .input {
  font: 13px Lato, Helvetica, Arial, sans-serif;
  line-height: inherit;
  color: #767676;
  margin: 0;
  padding: 0;
  height: auto;
}

.tags-input .tags .tag-item {
  margin: 0 10px 0 0;
  padding: 0;
  height: auto;
  font: 13px Lato, Helvetica, Arial, sans-serif;
  line-height: inherit;
  border: none;
  background: transparent;
}

.tags-input .tags .tag-item .remove-button {
  margin: 0;
}

.tags-input .autocomplete {
  border-radius: 4px;
  z-index: 10;
}

.tags-input .autocomplete .suggestion-item {
  font: 13px Lato, Helvetica, Arial, sans-serif;
  padding: 3px 20px;
}

.tags-input .autocomplete .suggestion-item em {
  font: 13px Lato, Helvetica, Arial, sans-serif;
  font-weight: bold;
}

.tags-input .autocomplete .suggestion-item.selected,
.tags-input .autocomplete .suggestion-item.selected em {
  color: #fff;
  background-color: #474752;
}

.tags-input.ng-invalid .tags {
  box-shadow: none;
}

.tags-input .tags .tag-item .remove-button:hover {
  text-decoration: none;
}

.tags-input .tags .tag-item .remove-button:active {
  color: #0d0d0d;
}

.tags-input .tags {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.tags-input .tags .tag-list {
  flex: 0 0 auto;
}

.tags-input input {
  vertical-align: top;
  border: none;
  height: 20px;
}

/*! Plupload */

.btn-image-upload {
  cursor: pointer;
  vertical-align: top;
  text-align: center;
  color: #999;
  background-color: #F8F8F8;
  border-radius: 6px;
}

.btn-image-upload .fa {
  font-weight: bold;
  font-size: 3.6em;
}

.btn-image-upload:active,
.btn-image-upload:hover {
  background-color: #CCC;
  color: #FFF;
}

.btn-image-upload::selection {
  background: none;
  color: #666;
}

.btn-image-upload button {
  border: none;
  background: transparent;
}

.progress.active {
  background-color: rgba(242, 244, 248, 0.5);
}

.gallery-wrapper {
  position: relative;
  min-height: 205px;
}

.preview-wrapper {
  text-align: center;
  padding-bottom: 20px;
}

.preview-wrapper .ng-isolate-scope.upload {
  position: relative;
  top: auto;
  left: auto;
}

.gallery-wrapper .progress,
.preview-wrapper .progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
}

.file-wrapper .progress {
  height: 3px;
  margin-top: 10px;
}

/* Gallery sizes */

.btn-image-upload,
.as-sortable-placeholder,
.as-sortable-dragging,
.image-thumb {
  position: relative;
  display: inline-block !important;
  width: 150px;
  height: 150px;
  text-align: center;
  line-height: 150px;
  overflow: hidden;
  margin: 0 20px 20px 0;
  border-radius: 10px;
}

.btn-image-upload button {
  width: 100%;
  height: 150px;
}

.image-thumb img {
  max-width: 100%;
  max-height: 100%;
}

.as-sortable-dragging {
  position: fixed;
  background: #999;
}

/*! Sortable */

.as-sortable {
  padding: 0;
  vertical-align: top;
}

.as-sortable-item {
  border: none;
  background: transparent;
}

.as-sortable-placeholder {
  background: #777;
}

.image-buttons {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  text-align: center;
  -webkit-transition: 0.2s ease-in opacity;
  -khtml-transition: 0.2s ease-in opacity;
  -moz-transition: 0.2s ease-in opacity;
  -ms-transition: 0.2s ease-in opacity;
  transition: 0.2s ease-in opacity;
}

.image-thumb:hover:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
}

.image-buttons:hover {
  opacity: 1;
}

.image-buttons .fa:active,
.image-buttons .fa:hover,
.image-buttons .fa {
  color: #FFF;
  text-decoration: none;
}

.image-buttons .fa {
  line-height: 150px;
  font-size: 36px;
  vertical-align: sub;
  cursor: pointer;
}

.image-buttons .fa.fa-arrows {
  font-size: 32px;
}

.image-buttons .fa.fa-paint-brush {
  font-size: 32px;
}

/*! Select */

.btn-default {
  color: #767676;
  background-color: #fff;
  border-color: #e2e2e4;
}

.dropdown-menu>.active>a,
.dropdown-menu>.active>a:focus,
.dropdown-menu>.active>a:hover {
  background-color: #a3c86d;
}

.btn-select {
  display: block;
  width: 100%;
  text-align: left;
  overflow: hidden;
  overflow-x: scroll;
}

/*! Languages */

.panel .panel-heading .langs-menu,
.modal .modal-header .langs-menu {
  position: absolute;
  top: 10px;
  right: 15px;
}

.change-lang-label {
  position: absolute;
  top: 16px;
  right: 50px;
}

.langs-menu .caret {
  vertical-align: super;
}

.langs-menu .btn.dropdown-toggle {
  padding: 6px 12px;
}

.btn-group.langs-menu .btn.dropdown-toggle {
  border: none;
  background: transparent;
  padding: 6px 3px 6px 10px;
}

.btn-group.open .dropdown-toggle {
  background: rgba(0, 0, 0, 0.1);
  box-shadow: none;
}

.langs-menu .dropdown-toggle:active,
.langs-menu .dropdown-toggle:hover {
  color: #444;
}

.langs-menu .dropdown-menu {
  left: auto;
  right: 0;
  min-width: 0;
  padding-bottom: 3px;
}

.langs-menu .dropdown-menu > li > a {
  padding: 3px 0;
}

.btn-clipboard-wysiwyg,
.btn-clipboard {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  display: block;
  font-size: 12px;
  color: #777;
  cursor: pointer;
  background-color: #FFF;
}

.btn-clipboard-wysiwyg .btn.dropdown-toggle,
.btn-clipboard .btn.dropdown-toggle {
  padding: 5px 10px;
  border: 1px solid #E1E1E8;
  border-radius: 4px 0 4px 0;
}

.btn-clipboard-wysiwyg {
  top: 27px;
}

/*! Tabs */

.wizard-steps {
  margin: 0 15px 15px;
}

.wizard-steps .step {
  text-transform: uppercase;
  font-weight: bold;
  opacity: 0.5;
  padding: 15px 15px 20px;
  box-sizing: border-box;
  border-left: 1px solid #E2E2E4;
  cursor: pointer;
}

.wizard-steps .step:first-child {
  border-left: none;
}

.wizard-steps .step .number {
  padding-right: 5px;
  color: #999;
}

.wizard-steps .step .number:after {
  content: ".";
}

.wizard-steps .step.done {
  opacity: 0.8;
}

.wizard-steps .step.current {
  opacity: 1;
}

.wizard-steps .progress {
  margin: 0 auto;
  height: 5px;
  border-radius: 20px;
  overflow: hidden;
  background-color: #E2E2E4;
}

/*! Badge */

.badge-white {
  background-color: #FFF;
  color: #999;
}

.badge-white.text-muted {
  color: #999999;
}

.badge-white.text-primary {
  color: #674e9e;
}

a.badge-white.text-primary:hover {
  color: #513d7c;
}

.badge-white.text-success {
  color: #70844b;
}

a.badge-white.text-success:hover {
  color: #546339;
}

.badge-white.text-info {
  color: #4a7a91;
}

a.badge-white.text-info:hover {
  color: #395e6f;
}

.badge-white.text-warning {
  color: #947f3b;
}

a.badge-white.text-warning:hover {
  color: #70602c;
}

.badge-white.text-danger {
  color: #aa513d;
}

a.badge-white.text-danger:hover {
  color: #843f30;
}

.label .badge {
  padding: 2px 5px;
  font-size: 1em;
  margin-left: 3px;
}

.tl-content {
  display: inline-block;
}

.panel iframe,
.message-preview iframe {
  border: none;
  width: 100%;
  min-height: 75%;
}

.panel iframe.panel-body {
  padding: 0;
}

section {
  position: relative;
}

.close-button {
  color: #777;
  top: 5px;
  left: 6px !important;
  background: none;
  font-weight: bold;
  font-size: 15px;
  text-shadow: 0 1px 0px #EEE;
  cursor: pointer;
}

.close-button:before {
  content: "\00d7";
}

.close-button:hover {
  color: #FFF;
  text-decoration: none;
}

/*! Login */

#content.page-signin {
  position: relative;
  top: 80px;
  left: 0;
}

.line-thru {
  text-transform: uppercase;
}

.page-signin .line-thru:before,
.page-signup .line-thru:before {
  width: 140px;
  margin-left: -70px;
}

.page-signin .form-horizontal,
.page-signup .form-horizontal {
  margin-top: 25px;
  margin-bottom: 30px;
}

/*! Category */

.categories .panel {
  color: #767676;
  border-color: #e0e4ec;
}

.categories .panel-heading {
  padding: 10px 15px;
  background-color: #f2f2f2;
}

.categories .panel-body {
  padding: 10px;
}

.categories .item-manage-buttons a,
.categories .item-manage-buttons span {
  color: #999;
}

.angular-ui-tree-nodes .angular-ui-tree-nodes {
  padding-left: 0;
}

.angular-ui-tree-node,
.angular-ui-tree-placeholder {
  margin-bottom: 10px;
}

/* Progress bar forced */

.page .progress.header-progress .progress-bar {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  -o-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}

.page .progress.header-progress {
  height: 7px;
}

/* Table filters */

.btn-default.active,
.btn-default:active,
.btn-default:focus,
.btn-default:hover {
  background: inherit;
  color: inherit;
}

.table-dynamic .table-filters button {
  outline: none;
}

.table>tbody>tr.muted>td {
  background-color: #eee;
}

.table .image-thumb {
  width: 50px;
  height: 50px;
  line-height: 50px;
  margin: 0 10px 0 0;
}

/*! Timeline */

.tl-content {
  max-width: 100%;
}

.tl-tile {
  margin-top: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.tl-caption .btn-muted {
  color: #FFF;
  background-color: #CCC;
  border-color: #AAA;
}

.tl-entry .label {
  display: inline-block;
  margin: .2em 0;
}